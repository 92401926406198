<template>
  <b-overlay
    :show="isBusy"
    rounded="sm"
  >
    <b-card>
      <app-echart-line
        v-if="option"
        :style-obj="{width: '100%', height:'100vh'}"
        :option-data="option"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    AppEchartLine,
    BOverlay,
  },
  props: {
    filePath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      option: null,
    }
  },
  created() {
    this.fetchTelemetryPlot()
  },
  methods: {
    fetchTelemetryPlot() {
      this.isBusy = true
      store.dispatch('app-asset-log/fetchTelemetryPlot', this.filePath)
        .then(response => {
          const { data } = response
          this.isBusy = false
          this.option = {
            title: {
              text: `${data.time.length} Points`,
              left: 10,
            },
            legend: {
              data: ['Acceleration X', 'Acceleration Y', 'Acceleration Z'],
            },
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: false,
                },
                saveAsImage: {
                  pixelRatio: 2,
                },
              },
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            grid: [{
              left: '3%',
              right: '5.3%',
              top: '5%',
              bottom: '70%',
              containLabel: true,
            },
            {
              left: '3%',
              right: '5.3%',
              top: '35%',
              bottom: '35%',
              containLabel: true,
            },
            {
              left: '3%',
              right: '5.3%',
              top: '70%',
              bottom: '10%',
              containLabel: true,
            }],
            dataZoom: [
              {
                show: true,
                realtime: true,
                start: 30,
                end: 70,
                xAxisIndex: [0, 1, 2],
              },
              {
                type: 'inside',
                realtime: true,
                start: 30,
                end: 70,
                xAxisIndex: [0, 1, 2],
              },
            ],
            xAxis: [{
              type: 'category',
              name: 'Time (s)',
              data: data.time,
              silent: false,
              axisLabel: {

                formatter(value) {
                  return value.substring(0, value.length - 4)
                },

              },
              splitLine: {
                show: false,
              },
              splitArea: {
                show: false,
              },
            },
            {
              gridIndex: 1,
              type: 'category',
              name: 'Time (s)',
              data: data.time,
              silent: false,
              axisLabel: {

                formatter(value) {
                  return value.substring(0, value.length - 4)
                },

              },
              splitLine: {
                show: true,
              },
              splitArea: {
                show: false,
              },
            },
            {
              gridIndex: 2,
              type: 'category',
              name: 'Time (s)',
              data: data.time,
              silent: false,
              axisLabel: {

                formatter(value) {
                  return value.substring(0, value.length - 4)
                },

              },
              splitLine: {
                show: true,
              },
              splitArea: {
                show: false,
              },
            }],
            yAxis: [
              {
                type: 'value',
                name: 'Acceleration (g)',
                splitArea: {
                  show: false,
                },
              },
              {
                gridIndex: 1,
                type: 'value',
                name: 'Acceleration (g)',
                splitArea: {
                  show: false,
                },
              },
              {
                gridIndex: 2,
                type: 'value',
                name: 'Acceleration (g)',
                splitArea: {
                  show: false,
                },
                scale: true,
              },
            ],
            series: [
              {
                type: 'line',
                data: data.X,
                name: 'Acceleration X',
                // Set `large` for large data amount
                large: true,
                sampling: 'lttb',
              },
              {
                xAxisIndex: 1,
                yAxisIndex: 1,
                type: 'line',
                data: data.Y,
                name: 'Acceleration Y',
                // Set `large` for large data amount
                large: true,
                sampling: 'lttb',
              },
              {
                xAxisIndex: 2,
                yAxisIndex: 2,
                type: 'line',
                data: data.Z,
                name: 'Acceleration Z',
                // Set `large` for large data amount
                large: true,
                sampling: 'lttb',
              },
            ],
          }
        })
        .catch(error => {
          console.log(error)
          this.isBusy = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching telemetry data ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
