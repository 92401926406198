<template>
  <b-row>
    <!-- range -->
    <b-col md="12">
      <b-overlay
        :show="busy"
        rounded="sm"
        opacity="0.6"
      >
        <b-form-group>
          <b-input-group size="sm">
            <date-picker
              v-model="timeRange"
              type="datetime"
              outlined
              placeholder="Select display date range"
              style="width: 370px"
              value-type="timestamp"
              range
              size="lg"
              :disabled="busy"
              @close="validateTimeRange"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="busy"
                @click="requestTelemetry"
              >
                Request Telemetry
              </b-button>
            </b-input-group-append>
            <b-button
              variant="primary"
              class="ml-2"
              :disabled="busy"
              @click="requestTenMinTelemetry"
            >
              Request Last 10min Telemetry
            </b-button>
          </b-input-group>
        </b-form-group>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BOverlay,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    DatePicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BOverlay,
  },
  props: {
    asset: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      timeRange: [],
      busy: false,
    }
  },
  methods: {
    validateTimeRange() {
      if (this.timeRange.length === 2) {
        // check is range difference is less than 5 hour
        const diff = this.timeRange[1] - this.timeRange[0]
        if (diff > 18000000) {
          this.$bvToast.toast('Please select a range of maximum 5 hour', {
            title: 'Invalid range',
            variant: 'danger',
            solid: true,
          })
          this.timeRange = []
        }
      } else {
        this.timeRange = []
      }
    },
    requestTenMinTelemetry() {
      this.busy = true
      store.dispatch('app-asset-log/requestTenMinTelemetryFile', {
        deviceID: this.asset.deviceID,
        payload: {
          timeStart: 0,
          timeEnd: 0,
        },
      })
        .then(() => {
          this.busy = false
          this.$bvToast.toast('Telemetry requested successfully', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          // emit event to parent
          this.$emit('telemetry-requested')
        })
        .catch(e => {
          this.busy = false
          this.$bvToast.toast(`Failed to request telemetry${e.data}`, {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
          this.$emit('telemetry-requested')
        })
    },
    requestTelemetry() {
      this.busy = true
      if (this.timeRange.length === 2) {
        // check is range difference is less than 5 hour
        const diff = this.timeRange[1] - this.timeRange[0]
        if (diff > 18000000) {
          this.$bvToast.toast('Please select a range of maximum 5 hour', {
            title: 'Invalid range',
            variant: 'danger',
            solid: true,
          })
          this.timeRange = []
          this.busy = false
        } else {
          store.dispatch('app-asset-log/requestTelemetryFile', {
            deviceID: this.asset.deviceID,
            payload: {
              timeStart: this.timeRange[0],
              timeEnd: this.timeRange[1],
            },
          })
            .then(() => {
              this.busy = false
              this.$bvToast.toast('Telemetry requested successfully', {
                title: 'Success',
                variant: 'success',
                solid: true,
              })
              // emit event to parent
              this.$emit('telemetry-requested')
            })
            .catch(e => {
              this.busy = false
              this.$bvToast.toast(`Failed to request telemetry${e.data}`, {
                title: 'Error',
                variant: 'danger',
                solid: true,
              })
              this.$emit('telemetry-requested')
            })
        }
      } else {
        this.timeRange = []
        this.busy = false
      }
    },
  },
}
</script>
