<template>
  <b-card
    class="mb-2"
    no-body
  >
    <br>
    <div class="custom-search d-flex justify-content-end">
      <feather-icon
        v-if="!isBusy"
        icon="RefreshCwIcon"
        size="30"
        class="text-body align-middle mr-30 m-1"
        @click="fetchTelemetries"
      />
      <b-spinner
        v-if="isBusy"
        label="Loading..."
        variant="primary"
        class="text-body align-middle mr-30 m-1"
      />
      <telemetry-request
        class="m-1"
        :asset="asset"
        @telemetry-requested="fetchTelemetries"
      />
    </div>
    <b-table
      id="telemetry-list-table"
      responsive="sm"
      show-empty
      :fields="fields"
      :items="items"
      primary-key="id"
      :busy="isBusy"
      sort-by="requestedAt"
      :sort-desc="true"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner
            class="align-middle"
            variant="primary"
          />
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(filename)="row">
        {{ row.item.download? row.item.filename : row.item.info }}
      </template>
      <template #cell(timeStart)="row">
        {{ convertDate(row.item.timeStart) }}
      </template>
      <template #cell(timeEnd)="row">
        {{ convertDate(row.item.timeEnd) }}
      </template>
      <template #cell(requestedAt)="row">
        {{ convertDate(row.item.requestedAt) }}
      </template>
      <template #row-details="row">
        <asset-telemetry-graph
          :key="row.item.filename"
          :file-path="row.item.path"
        />
      </template>
      <template #cell(action)="row">
        <b-button
          v-if="row.item.request"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="requestAlertTelemtetry(row.item)"
        >
          <feather-icon icon="DownloadCloudIcon" />
        </b-button>
        <b-button
          v-if="row.item.download"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="row.toggleDetails"
        >
          <feather-icon icon="BarChart2Icon" />
        </b-button>
        <b-button
          v-if="row.item.notification"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="clearNotification(row.item)"
        >
          <feather-icon icon="BellOffIcon" />
        </b-button>
        <span>
          <b-dropdown
            v-if="row.item.download && !isDownloading"
            variant="outline-primary"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="DownloadIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-asset
              class="choose-button"
              @click="downLoadFile(row.item.path+'?format=csv','csv')"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>CSV</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-asset
              @click="downLoadFile(row.item.path+'?format=json','json')"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>JSON</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-asset
              @click="downLoadFile(row.item.path+'?format=parquet','parquet')"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>PARQUET</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>
      <template #empty="scope">
        <h4>No data found</h4>
      </template>
      <template #emptyfiltered="scope">
        <h4>No data found</h4>
      </template>
    </b-table>
    <div class="custom-search d-flex justify-content-end">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="telemetry-list-table"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BTable, BButton, BSpinner, BDropdown, BDropdownItem, BCard, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { msEpochToDate } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AssetTelemetryGraph from './AssetTelemetryGraph.vue'
import TelemetryRequest from './TelemetryRequest.vue'

export default {
  components: {
    BTable,
    BButton,
    BSpinner,
    AssetTelemetryGraph,
    BDropdown,
    BDropdownItem,
    TelemetryRequest,
    BCard,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    asset: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      isDownloading: false,
      perPage: 14,
      currentPage: 1,
      rows: 0,
      items: [],
      fields: [
        {
          key: 'deviceID', stickyColumn: true, isRowHeader: true, label: 'Device ID',
        },
        {
          key: 'timeStart', stickyColumn: true, isRowHeader: true, label: 'From', sortable: true, primary: true,
        },
        {
          key: 'timeEnd', stickyColumn: true, isRowHeader: true, label: 'To', sortable: true,
        },
        {
          key: 'filename', stickyColumn: true, isRowHeader: true, label: 'File', sortable: true,
        },
        {
          key: 'requestedBy', stickyColumn: true, isRowHeader: true, label: 'Requested By', sortable: true,
        },
        {
          key: 'requestedAt', stickyColumn: true, isRowHeader: true, label: 'Requested At', sortable: true,
        },
        {
          key: 'action', label: 'Action', sortable: false, filterable: false,
        },
      ],
    }
  },
  created() {
    this.fetchTelemetries()
  },
  methods: {
    fetchTelemetries() {
      this.isBusy = true
      store.dispatch('app-asset-log/fetchTelemetries', this.asset.deviceID)
        .then(response => {
          const { data } = response
          this.items = data
          this.rows = data.length
          this.isBusy = false
        })
        .catch(e => {
          this.isBusy = false
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Asset Info',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    downLoadFile(path, type) {
      this.isDownloading = true
      store.dispatch('app-asset-log/fetchTelemetryFile', path)
        .then(response => {
          this.isDownloading = false
          const { data } = response
          const content = type === 'csv' ? data : JSON.stringify(data)
          const blob = new Blob([content], { type: `application/${type}` })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${path}.${type}`
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(e => {
          this.isDownloading = false
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unable to download file',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    requestAlertTelemtetry(item) {
      store.dispatch('app-asset-log/requestTelemetryFile', {
        deviceID: item.deviceID,
        payload: {
          timeStart: item.timeStart,
          timeEnd: item.timeEnd,
        },
      })
        .then(() => {
          this.busy = false
          this.$bvToast.toast('Telemetry requested successfully', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.fetchTelemetries()
        })
        .catch(e => {
          this.busy = false
          this.$bvToast.toast(`Failed to request telemetry${e.data}`, {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
          this.fetchTelemetries()
        })
    },
    clearNotification(item) {
      store.dispatch('app-asset-log/clearNotification', item.id)
        .then(() => {
          this.busy = false
          this.$bvToast.toast('Notification cleared successfully', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.fetchTelemetries()
        })
        .catch(e => {
          this.busy = false
          this.$bvToast.toast(`Failed to clear notification${e.data}`, {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
          this.fetchTelemetries()
        })
    },
    convertDate(val) {
      return msEpochToDate(val)
    },
  },
}
</script>
